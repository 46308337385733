import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background-color: #D30046;
  box-sizing: border-box;
  color: #FFF;
  font-family: 'Open Sans', sans-serif;
  margin: 2rem
  max-width: 49rem;
  min-width: 28rem;
  padding: 7rem 7rem 6.5rem;
  text-align: center;
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.4rem;
    margin-top: 4rem;
  }
  
`;