import styled from 'styled-components';

const PageWrapper = styled.div`
	position: relative;
	text-align: center;
	h1 {
		@media (max-width: 600px) {
			font-size: 3rem;
		}
	}
	.button {
		background-color: transparent;
		border: 2px solid #D30046;
		color: #FFF;
		cursor: pointer;
		display: inline-block;
		font-size: 2.4rem;
		font-weight: bold;
		margin-top: 3.6rem;
		padding: 2.5rem 5rem;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: background-color 0.15s ease-in;
		&:hover {
			background-color: #D30046;
		}
	}
	.about-body {
		align-items: center;
		color: #FFF;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		font-family: "Open Sans", sans-serif;
		margin: 20rem auto 5rem;
		max-width: 160rem;
		position: relative;
	}
	.floating-image {
		left: 18%;
    max-width: 60rem;
		min-width: 20rem;
		position: absolute;
		top: -5%;
		width: 30vw;
		@media (max-width: 600px) {
			left: 50%;
			top: -9%;
			transform: translateX(-50%);
		}
		&.bottom {
			min-width: 0;
			position: static;
			width: auto;
		@media (max-width: 600px) {
				display: none;
			}
		}
	}
	.about-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media (max-width: 600px) {
			display: none;
		}
		img {
			margin-bottom: 5rem;
			max-width: 77rem;
			min-width: 20rem;
			width: 50vw;
		}
	}
	.underline-header {
		align-items: flex-start;
		margin: 0;
		&:after {
			width: 10rem;
		}
	}
	.text-box {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding-left: 10%;
		max-width: 64rem;
		min-width: 25rem;
		position: relative;
		@media (max-width: 700px) {
			padding: 0 2rem 0 1rem;
		}
		img {
	    margin-left: -20vw;
	    margin-top: 28%;
	    width: 35vw;
		}
	}
	p {
		font-size: 1.8rem;
		line-height: 1.7;
		margin-top: 4rem;
	}
	h2 {
		font-family: "Open Sans", sans-serif;
		text-transform: uppercase;
		font-size: 6rem;
		font-weight: bold;
		margin-top: 5rem;
	}
	.text-box .button {
		align-self: flex-start;
	}
	.core-pillars {
		margin-top: 10rem;
		text-align: center;
		.underline-header {
			align-items: center;
		}
		.pillar-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 4rem;
			max-width: 160rem;
			width: 100%;
		}
	}
`;

export default PageWrapper;