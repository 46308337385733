import React, { Component } from 'react';
import PageWrapper from '../styles/about.css.js';
// import HomeHeader from '../components/Home/HomeHeader/HomeHeader';
import Pillar from '../components/Global/Pillar/Pillar';
import { Link } from 'gatsby';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <PageWrapper>
{/*        <HomeHeader 
          headerImage="AboutUsHeaderBG.jpg"
          rotate={false}
          button={true}
          buttonCopy={'Explore Careers'}
          headlineCopy={'We Are Red Beard Games'}
          buttonPath="/careers"
        />*/}
        <div className="about-body">
          <img className="floating-image" src="https://webcdn.hirezstudios.com/redbeardgames/images/RedBeardSquareImage.jpg"/>
          <div className="about-box">
            <img src="https://webcdn.hirezstudios.com/redbeardgames/images/BrightonImage.jpg" />
            <div className="text-box">
              <div className="underline-header">About</div>
              {/*<h2>We Are Wizards</h2>*/}
              <p>Red Beard Games are the newest game development studio owned by video game developer and publisher Hi-Rez Studios. We’re an ambitious team located in the center of bustling Brighton, UK. Our goal is to deliver amazing experiences tailored to the interests of large gaming communities.</p>
              <Link className="button" to="/careers">Start Your Journey</Link>
              <img className="floating-image bottom" src="https://webcdn.hirezstudios.com/redbeardgames/images/RedBeardGames.jpg" />
            </div>
          </div>
          <div className="core-pillars">
            <div className="underline-header">Our Core Pillars</div>
            <div className="pillar-container">
              <Pillar 
                title="Iterative"
                copy="We believe that the best games are born from experimentation… No idea is too stupid, no concept is too bizarre. With constant Iteration and improvement being fundamental to building experiences that gamers will enjoy and revisit."
              />
              <Pillar 
                title="Collaborative"
                copy="As a small team, we understand that cooperation is essential to success. At Red Beard Games we value opinions and ideas from the entire team equally. It’s through this unique sense of respect and camaraderie that we can create exceptional videogames and levels of service."
              />
              <Pillar 
                title="Creative"
                copy="We are creating distinctive experiences, tailor-made for the largest gaming communities in the world. This can only be achieved with an absolute focus on the evolution of new ideas and a refusal to accept convention."
              />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  };
}

export default About;
